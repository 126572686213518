import { h } from "preact"
import { useRef, useEffect } from "preact/hooks"
import { Link } from "preact-router"

const Header = () => {

  const ref = useRef()

  useEffect(() => {
    if (!window) return

    window.onscroll = () => {
      if (document.body.scrollTop > 10 || document.documentElement.scrollTop > 10) {
        ref.current.className = 'header scrolled'
      } else {
        ref.current.className = 'header'
      }
    }

    return () => { }
  }, [])

  return (
    <header class="header" ref={ref}>
      <div class="container">
        <div class="row flexbox-center">
          <div class="col-lg-2 col-md-3 col-6">
            <div class="logo">
              <img src="assets/img/logo.png" alt="BrokerDeals Logo" />
            </div>
          </div>
          <div class="col-lg-10 col-md-9 col-6">
            <div class="responsive-menu" />
            <div class="mainmenu">
              <ul id="primary-menu">
                <li><Link className="nav-link" activeClassName="active" href="/">Home</Link></li>
                <li><Link className="nav-link" activeClassName="active" href="/buy">Buy IPv4</Link></li>
                <li><Link className="nav-link" activeClassName="active" href="/sell">Sell IPv4</Link></li>
                {/* <li><Link className="nav-link" activeClassName="active" href="/brokers">Brokers</Link></li> */}
                <li><Link className="nav-link" activeClassName="active" href="/policy">Policy</Link></li>
                <li><Link className="nav-link" activeClassName="active" href="/contact">Contact Us</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header