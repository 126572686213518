import '../bootstrap-scss/custom.scss';
import './assets/css/icofont.css'
import './assets/css/style.css'
import './assets/css/responsive.css'

import { h } from 'preact'
import { Router } from 'preact-router'

import Header from './components/header'
import Footer from './components/footer'

// Code-splitting is automated for routes
import Home from './routes/home'
import Buy from './routes/buy'
import Sell from './routes/sell'
import Brokers from './routes/brokers'
import Broker from './routes/broker'
import Policy from './routes/policy'
import Contact from './routes/contact'

const App = ({ url }) => (
  <div id="app">
    <Header />

    <Router url={url}>
      <Home path="/" />
      <Buy path="/buy" />
      <Sell path="/sell" />
      <Brokers path="/brokers" />
      <Broker path="/broker/:id" />
      <Policy path="/policy" />
      <Contact path="/contact" />
    </Router>

    <div className="at-footer">
      <Footer />
    </div>
  </div>
)

export default App